<template>
  <div>
    <h1 class="title" v-if="service">{{ service.name }}</h1>
    <div class="d-flex mtClass">
      <div class="col-md p-0">
        <div class="col-md-12">
          <ValidationObserver ref="fields" class="row">
            <div
              v-for="(field, index) in tagsDefault"
              :key="index"
              :class="
                field.fieldName !== 'Chassi' ? 'my-1 p-1 col-6' : 'my-1 p-1 col'
              "
            >
              <ValidationProvider v-slot="{ classes }" tag="div">
                <div class="d-flex align-items-center">
                  <label class="mb-2 label_default">{{
                    field.fieldName.toUpperCase()
                  }}</label>
                  <div v-if="field.isRequired" class="mx-2 requeridLabel">
                    *
                  </div>
                </div>
                <div>
                  <!-- :mask="field.fieldName == 'Placa' ? 'XXXXXXX' : maskValue(field.fieldMask)" -->
                  <input
                    type="text"
                    v-if="stringEmail(field.fieldMask)"
                    :class="
                      `form-control getValue  ${concatName(field.fieldName)}`
                    "
                    :placeholder="field.fieldMask"
                    :name="field.fieldName"
                    :required="field.isRequired"
                    @blur.native="
                      focusFieldValidation(
                        $event,
                        field,
                        concatName(field.fieldName)
                      )
                    "
                  />
                  <div v-else-if="field.fieldTag.includes('money')">
                    <money
                      v-bind="money"
                      :class="
                        `form-control getValue  ${concatName(field.fieldName)}`
                      "
                    ></money>
                  </div>
                  <the-mask
                    v-else
                    type="text"
                    :class="
                      `form-control getValue  ${concatName(field.fieldName)}`
                    "
                    :mask="
                      field.requiredMask
                        ? field.fieldMask
                        : returnItens(field.fieldLenght)
                    "
                    :placeholder="field.fieldMask"
                    :name="field.fieldName"
                    :tokens="hexTokens"
                    :required="field.isRequired"
                    @blur.native="
                      focusFieldValidation(
                        $event,
                        field,
                        concatName(field.fieldName)
                      )
                    "
                  />
                </div>
              </ValidationProvider>
            </div>
          </ValidationObserver>
        </div>
        <div class="col-md-12 containerServices">
          <div class="dados">
            <h3>Dados Proprietário</h3>
            <hr />
          </div>
          <div class="row p-3">
            <div
              v-for="(field, index) in filtersParamsProprietário"
              :key="index"
              :class="
                field.fieldName !== 'Chassi' ? 'my-1 p-1 col-6' : 'my-1 p-1 col'
              "
            >
              <ValidationProvider v-slot="{ classes }" tag="div">
                <div class="d-flex align-items-center">
                  <label class="mb-2 label_default">{{
                    field.fieldName.toUpperCase()
                  }}</label>
                  <div v-if="field.isRequired" class="mx-2 requeridLabel">
                    *
                  </div>
                </div>
                <div>
                  <input
                    type="text"
                    v-if="
                      stringEmail(field.fieldMask) ||
                        stringLocal(field.fieldMask)
                    "
                    :class="
                      `form-control getValue ${concatName(field.fieldName)}`
                    "
                    :placeholder="field.fieldMask"
                    :name="field.fieldName"
                    :required="field.isRequired"
                    @blur.native="
                      focusFieldValidation(
                        $event,
                        field,
                        concatName(field.fieldName)
                      )
                    "
                  />
                  <the-mask
                    v-else
                    type="text"
                    :class="
                      `form-control getValue ${concatName(field.fieldName)}`
                    "
                    :mask="
                      field.requiredMask
                        ? field.fieldMask
                        : returnItens(field.fieldLenght)
                    "
                    :placeholder="field.fieldMask"
                    :name="field.fieldName"
                    :tokens="hexTokens"
                    @blur.native="
                      focusFieldValidation(
                        $event,
                        field,
                        concatName(field.fieldName)
                      )
                    "
                  />
                </div>
              </ValidationProvider>
            </div>
          </div>
          <!-- {{filtersParamsProprietário}} -->
        </div>
        <div class="col-md-12 mt-3">
          <div class="dados">
            <h3>Dados Comprador</h3>
            <hr class="mb-0" />
          </div>
          <div class="row p-2">
            <div
              v-for="(field, index) in filtersParamsComprador"
              :key="index"
              :class="
                field.fieldName !== 'Chassi' ? 'my-1 p-1 col-6' : 'my-1 p-1 col'
              "
            >
              <ValidationProvider
                v-slot="{ errors, ariaMsg, classes }"
                tag="div"
              >
                <div class="d-flex align-items-center">
                  <label class="mb-2 label_default">{{
                    field.fieldName.toUpperCase()
                  }}</label>
                  <div v-if="field.isRequired" class="mx-2 requeridLabel">
                    *
                  </div>
                </div>
                <div>
                  <input
                    type="text"
                    v-if="
                      stringEmail(field.fieldMask) ||
                        stringLocal(field.fieldMask)
                    "
                    :class="
                      `form-control getValue  ${concatName(field.fieldName)}`
                    "
                    :placeholder="field.fieldMask"
                    :name="field.fieldName"
                    :required="field.isRequired"
                    @blur.native="
                      focusFieldValidation(
                        $event,
                        field,
                        concatName(field.fieldName)
                      )
                    "
                  />
                  <the-mask
                    v-else
                    type="text"
                    :class="
                      `form-control getValue  ${concatName(field.fieldName)}`
                    "
                    :mask="
                      field.requiredMask
                        ? field.fieldMask
                        : returnItens(field.fieldLenght)
                    "
                    :placeholder="field.fieldMask"
                    :name="field.fieldName"
                    :tokens="hexTokens"
                    :required="field.isRequired"
                    @blur.native="
                      focusFieldValidation(
                        $event,
                        field,
                        concatName(field.fieldName)
                      )
                    "
                  />
                  <!-- data-="" -->
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <!-- <uploadFileVue v-if="filter.upload" /> -->
      </div>
      <documentRequeridVue
        class="col-md-6"
        :isUpload="false"
        :message="textInfo"
        :documents="documents"
        v-if="documents.length > 0"
      />
    </div>
  </div>
</template>

<script>
import uploadFileVue from "./upload/uploadFile.vue";
import documentRequeridVue from "./document/documentRequerid.vue";
import { TheMask } from "vue-the-mask";
import { Money } from "v-money";
import { HalfCircleSpinner } from "epic-spinners";

export default {
  components: {
    TheMask,
    uploadFileVue,
    documentRequeridVue,
    HalfCircleSpinner,
    Money
  },
  props: {
    filtersParamsProprietário: {
      type: [Object, Array],
      default: []
    },
    tagsDefault: {
      type: [Object, Array],
      default: []
    },
    filtersParamsComprador: {
      type: [Object, Array],
      default: []
    },
    textInfo: {
      type: [Object, Array, String],
      default: ""
    },
    valueValidation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hexTokens: {
        G: { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase() }, // numero com Letras Mai
        "#": { pattern: /\d/ }, // Numero
        X: { pattern: /[0-9a-zA-Z]/ }, // Numeros e letras
        A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
        a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
        T: { pattern: /[0-9a-zA-Z-@.]/ },
        "!": { escape: true }
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      service: undefined,
      documents: [
        {
          name: "teste"
        }
      ],
      tags: [],
      fieldvalues: [],
      vehiclePlateValue: "",
      load: false,
      sendError: false,
      allServiceParams: [],
      serviceParametrization: false,
      tagsDefault: []
    };
  },
  methods: {
    stringEmail(value) {
      return value.indexOf("@") > -1;
    },
    stringLocal(value) {
      let array = [
        "ENDEREÇO",
        "CIDADE",
        "BAIRRO",
        "COMPL.",
        "DIGITE O NOME COMPLETO"
      ];
      return array.some(s => value.includes(s));
    },

    maskValue(item, letter) {
      const itens = item.replaceAll(".", "").replaceAll("-", "");
      let amount = [];
      for (let i = 0; i < itens.length; i++) {
        amount.push(isNaN(itens) ? "X" : "P");
      }
      return amount.join("");
    },

    returnItens(item) {
      let forItens = [];
      for (let i = 0; i <= item; i++) {
        forItens.push("T");
      }
      return forItens.join("");
    },

    concatName(item) {
      const itens = item.split(" ");
      if (itens.length > 1) {
        return itens.join("");
      }

      return itens[0];
    },

    focusFieldValidation(event, field, name) {
      const { fieldName, isRequired } = field;
      const { value, classList } = event.target;

      if (value && isRequired) {
        classList.forEach(element => {
          if (element === name) {
            const item = document.querySelector(`.${element}`);
            item.classList.remove("requerid");
          }
        });
      }
    }
  },
  watch: {
    valueValidation(newValue, oldValue) {
      if (newValue) {
        const doc = document.querySelectorAll(".getValue");
        doc.forEach(item => {
          if (item.value == "" && item.required) {
            item.classList.add("requerid");
          }
        });
      }
    }
  }
};
</script>

<style scoped>
* {
  font-style: normal;
  font-weight: 700;
}

.title {
  margin: 24px 0;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.containerForms {
  width: 369px;
}
.label_default {
  font-weight: 300;
  font-size: 13px;
  color: #ffffff;
}

.form-control {
  background: #ffffff;
  /* border: 1px solid #98C4E6; */
  border-radius: 6px;
  height: 40px;
  background-color: #f6f6f6 !important;
  font-weight: 500 !important;
  color: #868686;
}

::-webkit-input-placeholder {
  color: #868686;
  opacity: 0.5;
}

.containerServices {
  background: #1b60a89e;
  border-radius: 12px;
  padding: 20px;
}

.dados h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: "liga" off;
  color: #ffffff;
}

hr {
  background: #fff;
}
.requerid {
  border: 1px solid red;
  color: red;
}
.requeridLabel {
  color: red;
}
</style>
