<template>
  <div class="container md-10">
    <div>
      <header class="mt-5">
        <ul class="d-flex align-content-lg-between align-items-center mb-2">
          <li class="mr-1">Home</li>
          <li class="mr-1">/ Despachante</li>
          <li class="text-white">/ {{ nameService }}</li>
        </ul>
      </header>
    </div>
    <half-circle-spinner
      :animation-duration="1000"
      :size="100"
      color="#fff"
      class="mx-auto my-5"
      v-if="load"
    />
    <comps v-else />
  </div>
</template>

<script>
import HTTP from "@/api_system";
import comps from "@/components/despachante/containerDespachante.vue";
import { HalfCircleSpinner } from "epic-spinners";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    HalfCircleSpinner,
    comps
  },
  data() {
    return {
      nameService: "",
      load: true,
    };
  },
  mounted() {
    this.getService();
    this.setServiceDespachanteStatus(true);
  },
  computed: {
    ...mapGetters(["getOrderId"])
  },
  methods: {
    ...mapMutations([
      "setDataService",
      "changeOrderId",
      "changeOrderServiceId",
      "changeLoadService",
      "setServiceDespachanteStatus"
    ]),
    ...mapActions(["sendPedidos"]),
    async getService() {
      this.load = true;
      try {
        const { data } = await HTTP.get(
          `services/app/Service/GetService?id=${this.$route.params.id}`,
          {
            headers: {
              Authorization: `bearer ${this.$store.getters.getterToken}`
            }
          }
        );
        const { service } = data.result;
        this.setDataService(service);
        // await this.getCreateService(service.id);
        this.nameService = service.name;
        this.load = false;
      } catch (error) {
        console.error(error);
        this.toast("Ops algo deu errado", this.$toast.error);
        this.load = false;
      }
    },
    async getCreateService(serviceID) {
      try {
        const { data } = await HTTP.post(
          `services/app/Order/CreateOrEdit?serviceId=${serviceID}&orderId=${this.getOrderId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }
        );
        const { orderId, orderServiceId } = data.result;
        this.changeOrderId(orderId);
        this.changeOrderServiceId(orderServiceId);
        this.load = false;
      } catch (error) {
        console.error(error);
        this.toast(error.response.data.error.message, this.$toast.error);
        this.load = false;
      }
    }
  },
  watch: {
    "$route.params.id": function(id) {
      this.getService();
    }
  }
};
</script>

<style scoped>
* {
  list-style: none;
  padding: 0;
}

li {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #98c4e6;
}

header {
  border-bottom: 2px solid #3f88c1;
}
</style>
