<template>
  <Modal
    :modalId="`modal_UploadFiles`"
    modalTitle="Upload de Arquivos"
    :load="false"
    :typeLg="'modal_wd'"
    @closeModal="closeModal"
  >
  <div class="containerFile" >
      <div v-if="load" class="containerSpiner">
        <half-circle-spinner
          :animation-duration="1000"
          :size="80"
          color="#f38235"
          class="mx-auto"
        />
      </div>
      <div v-else>
        <div class="d-flex align-content-center align-items-center justify-content-between">
          <h2 class="text-center">{{ file.length }} de {{ numberItens }} Arquivos válidos</h2>
          <button class="btnFile" @click="openFile">Selecionar Arquivo</button>
        </div>
        <div :class="file.length < 1 ? 'containerMain d-flex' : 'containerMain'">
          <h2 class="text-center notFile" v-if="file.length < 1">Nenhum Documento anexado</h2>
          <div class="containerPDF" v-else v-for="(item, index) in file" :key="index">
            <img src="@/assets/image/iconesUpload/2.svg" alt="pdf">
            <span class="mx-3">{{ item.name }}</span>
            <button class="closePDF" @click="closeItem(item)">x</button>
          </div>
        </div>
      </div>


      <div>
        <div class="d-flex w-25">
          <button class="btn btn_secondary mx-2" @click="closeModal"> Cancelar </button>
          <button class="btn btn_default d-flex justify-content-center mx-2" v-if="file.length < 1" @click="$emit('next')" :disabled="load">
            <span v-if="file.length < 1 && !load">Seguinte</span>
            <half-circle-spinner
              v-if="load"
              :animation-duration="1000"
              :size="20"
              color="#fff"
              class="mx-auto"
            />
          </button>
          <button class="btn btn_default d-flex justify-content-center mx-2" v-else @click="sendFiles" :disabled="load">
           <span v-if="!load">Upload</span>
            <half-circle-spinner
              v-else
              :animation-duration="1000"
              :size="20"
              color="#fff"
              class="mx-auto"
            />
          </button>
        </div>
      </div>
    </div>
    <input type="file" name="file" multiple ref="files" accept="application/pdf" class="none" @change="files($event)" />
  </Modal>
</template>

<script>
import Modal from '../../modal/modalDefault.vue'
import { mapGetters, mapActions } from 'vuex'
import { HalfCircleSpinner } from "epic-spinners";
export default {
  components: {
    Modal,
    HalfCircleSpinner
  },
  props: {
    load: {
      type: Boolean,
      default: false
    },
    numberItens: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      file: '',
    }
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    sendFiles () {
      this.$emit('sendFile', this.file)
    },
    files (event) {
      const { files, value } = event.target
      if(value.length > 0) {
        this.file = (event.target.files)
      }
    },
    closeModal () {
      $("#modal_UploadFiles").modal("hide");
    },
    openFile (event) {
      this.$refs.files.click()
    },
    nextPage () {
      this.$router.push({ path: "/finalizar-servico" }).then(() => {}).catch(() => {});
      $("#modal_UploadFiles").modal("hide");
    },
    closeItem (item) {
      const newValue = []

      this.file.forEach(items => {
        if(item != items) {
          newValue.push(items)
        }
      })
      this.file = newValue
    },
  },
}
</script>

<style scoped>
h2 {
  font-weight: 400;
  font-size: 14px;
  color: #5397CC;
}

.notFile {
  font-weight: 600;
  font-size: 16px;
  line-height: 113.2%;
  color: #98C4E6 !important;
  outline: none;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  border: none;
  background: none;
}

hr {
  border: 1px solid #a5a6ad4f;
}

.containerMain {
  min-height: 290px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.containerFile,
.containerSpiner {
  min-height: 420px;
  max-height: 520px;
  overflow: overlay;
  padding-right: 10px;
}

.containerSpiner {
  display: flex;
  align-items: center;
}

.containerFile h2 {
  color: #ff0000;
  font-size: 20px;
}

.btnFile {
  background: #3F88C1;
  border-radius: 4px;
  height: 40px;
  border: none;
  color: #fff;
  padding: 10px;
  margin: 10px 0;
}

.none {
  display: none;
}

.containerPDF {
  background: #b9d0e9;
  background: #dce6f1;
  padding: 10px 0;
  margin: 10px 0;
  border-radius: 7px;
  padding: 10px;
}

.containerPDF span {
  color: #FFFFFF;
  color: #1b60a8;
  font-size: 14px;
  font-weight: 500;
}

.closePDF {
  border: none;
  background: #e20613;
  color: #fff;
  border-radius: 7px;
  padding: 4px 8px;
  cursor: pointer;
}

</style>
