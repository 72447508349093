<template>
  <div class="mt-5">
    <h2>Anexar documentos para o processo.</h2>

    <input
      id="inputFilesID"
      @change="files($event)"
      type="file"
      name="file"
      multiple
      ref="files"
      class="none"
      accept="application/pdf"
    />
    <div class="containerFile">
      <div>
        <button @click="$emit('openModal', 'modal_UploadFiles', true)">
          Selecionar e Avançar
        </button>
        <p>Tamanho máximo de arquivo: 30mb.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HTTP from "@/api_system";
import { HalfCircleSpinner } from "epic-spinners";
import { HubConnectionBuilder } from "@aspnet/signalr";
export default {
  data() {
    return {
      file: ""
    };
  },
  computed: {
    ...mapGetters(["getOrderServiceId"])
  },
  methods: {
    openFiles() {
      this.$refs.files.click();
    },
    files(event) {
      this.file = event.target.files;
    },
    closeItem(item) {
      const newValue = [];

      this.file.forEach(items => {
        if (item != items) {
          newValue.push(items);
        }
      });
      this.file = newValue;
    },
    async ServiceDispatcherCreate() {
      // const jsonData = {
      //   "ordersServiceId": this.getOrderServiceId,
      // }
      const { file } = this;
      let data = new FormData();

      for (let i = 0; i < file.length; i++) {
        data.append(file[i].name, file[i]);
      }

      const item = {
        inputFile: data
      };
      HTTP.post(
        `services/app/OrderServiceFile/Create?orderServiceId=${this.getOrderServiceId}`,
        item.inputFile,
        {
          headers: {
            Authorization: `bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(res => {
          this.load = false;
        })
        .catch(err => {
          console.error(error);
          this.toast(error.response.data.error.message, this.$toast.error);
          this.load = false;
        });
    }
  }
};
</script>

<style scoped>
h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.containerFile {
  background: #1b60a8;
  border-radius: 12px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerFile button,
.btn {
  background: #f38235;
  border-radius: 6px;
  border: none;
  color: #fff;
  height: 40px;
  padding: 0 20px;
  font-weight: 600;
  font-size: 16px;
  transition: 0.2s ease-in-out;
  outline: none;
}

.containerFile button:hover {
  background: rgba(216, 109, 37, 0.884);
}
.containerFile p {
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  margin: 5px 0;
  text-align: center;
}

.none {
  display: none;
}

.containerPDF {
  background: #1b60a8;
  padding: 10px 0;
  margin: 10px 0;
  border-radius: 7px;
}

.containerPDF span {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}

.closePDF {
  border: none;
  background: #e20613;
  color: #fff;
  border-radius: 7px;
  padding: 4px 8px;
  cursor: pointer;
}
</style>
