<template>
  <div>
    <div class="containerDocument">
      <div class="header">
        <h2 v-if="!isUpload && !message" class="m-0">Documentos necessários</h2>
        <h2 v-else class="m-0">Lembrete!</h2>
      </div>
      <ul v-if="!message">
        <li
          v-for="(item, index) in documents"
          :key="item.id"
          class="d-flex justify-content-between align-items-center"
        >
          <div class="d-flex align-items-center">
            <img
              v-if="fileSend[index] && fileSend[index].name != ''"
              :class="fileSend[index].name ? '' : 'visibleImg'"
              src="@/assets/image/icones/confirmDocument.png"
              alt="confirmDocument"
            />
            <font-awesome-icon
              icon="fas-regular fa-xmark"
              class="text-danger pr-2"
              v-else
            />
            <p class="m-0 textInit">
              {{ item.name }}
            </p>
          </div>
          <div
            v-if="fileSend[index] && fileSend[index].name != ''"
            class="d-flex"
          >
            <div class="d-flex mx-2 align-items-center justify-content-center">
              <font-awesome-icon
                class="colorIcon"
                icon="fas-regular fa-circle-info"
                data-toggle="tooltip"
                data-placement="right"
                :title="fileSend[index].name"
              />
            </div>
            <button
              class="btnIcons bg-danger"
              @click="closeItem(item.sequence)"
            >
              <font-awesome-icon icon="fas-regular fa-trash" />
            </button>
          </div>
          <button
            class="btnIcons defaultUpload"
            @click="openFile(item.sequence)"
            v-else
          >
            <font-awesome-icon icon="fas-solid fa-arrow-up-from-bracket" />
          </button>
          <input
            type="file"
            name="file"
            ref="files"
            accept="application/pdf"
            class="none"
            @change="files($event, item.sequence)"
            :id="item.sequence"
          />
        </li>
      </ul>
      <div
        v-else
        v-html="message[0].fieldName"
        class="text-center bg p-4"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    documents: {
      type: Array,
      default: () => []
    },
    isUpload: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      filesItens: [],
      fileSend: [],
      allFiles: []
    };
  },
  mounted() {
    this.enableTooltip();
    this.createItens();
  },
  methods: {
    ...mapMutations(["setUploadFilesItens"]),
    enableTooltip() {
      $(function() {
        $('[data-toggle="tooltip"]').tooltip();
      });
    },
    createItens() {
      this.setUploadFilesItens([]);
      const itens = this.documents.map(x => ({
        sequence: x.sequence,
        inputFile: "",
        name: ""
      }));
      if (this.filesItens.length > 0) return;

      this.filesItens = itens;
      this.setUploadFilesItens(itens);
    },
    files(event, index) {
      const { files, value } = event.target;
      const file = event.target.files;
      const renameFile = new File(
        event.target.files,
        `${index}* ${event.target.files[0].name}`
      );
      this.allFiles.push(renameFile);

      let itens = [...this.filesItens];

      if (value.length > 0) {
        itens.forEach(item => {
          if (item.sequence === index) {
            item.inputFile = file;
            item.name = file[0].name;
          }
        });
      }
      this.fileSend = itens;
    },
    openFile(id) {
      this.$refs.files.forEach(element => {
        if (id == element.id) {
          element.click();
        }
      });
    },

    closeItem(item) {
      let newValue = [...this.fileSend];
      newValue.forEach(items => {
        if (item == items.sequence) {
          items.inputFile = "";
          items.name = "";
        }
      });

      this.allFiles = this.allFiles.filter(s => !s.name.includes(`${item}* `));

      this.$refs.files.forEach((itemInput, index) => {
        if(item == index + 1) {
          itemInput.value = ''
        }
      })

      this.fileSend = newValue;
    }
  },
  watch: {
    allFiles: function() {
      this.setUploadFilesItens(this.allFiles);
    }
  }
};
</script>

<style scoped>
.containerDocument {
  max-width: 305px;
  margin: 0 auto;
}

.visibleImg {
  visibility: collapse;
}

.header {
  background: linear-gradient(270deg, #e0a638 0%, #f38235 100%);
  border-radius: 12px 12px 0px 0px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
}

.bg,
ul {
  background: #fff;
  list-style: none;
  border-radius: 0px 0px 12px 12px;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #797984;
  line-height: 1.5;
}

ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #e9e9eb;
  width: 90%;
  margin: 0 auto;
  font-weight: 400;
  font-size: 16px;
  color: #797984;
}

ul li img {
  padding-right: 10px;
}

.btnIcons {
  border: none;
  color: #fff;
  border-radius: 5px;
  transition: 0.1s ease-in-out;
  outline: none;
  padding: 3px 13px;
}

.colorIcon {
  color: #e1a438;
}

.defaultUpload {
  background: #e1a438;
}

.defaultUpload:hover {
  background: #f3aa2b;
}

.textInit {
  text-align: initial;
}


.none {
  display: none;
}
</style>
